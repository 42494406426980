<template>
	<div class="pagina">
		<div class="container">
	    	<div class="row">
	        	<div class="service">
	        		<div class="col-md-6 col-md-offset-3">
	            		<div class="text-center">
	              			<h2>Backend</h2>
	              			<p>Uso de librerias y servicios de Laravel.<br></p>
	            		</div>
	            	<hr>
	          		</div>
	        	</div>
	      	</div>
	    </div>
	    <div class="container">
	    	<div class="row">
	    		<div class="col-md-12">
	    			<iframe src="public/docs/Backend.pdf" width="100%" height="500px"></iframe>
	    		</div>
	    	</div>
	    </div>
	</div>
</template>
<script type="text/javascript">
	export default {

	}
</script>
